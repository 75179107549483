import React from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import {
  Paper,
  Tabs,
  Tab,
  makeStyles,
} from "@material-ui/core";
import { TabPanel, TabContext } from "@material-ui/lab";

import TabHeader from "../components/TabHeader";
import SelectResume from "../components/SelectResume";
import PersonalInfo from "../components/PersonalInfo";
import WorkHistory from "../components/workHistory/WorkHistory";
import Educations from "../components/Educations";
import Skills from "../components/Skills";
import ResumePreview from "../components/resumePreview";

import { resumeActions } from "../_redux/resumeActions";

export const ResumeBuilder = () => {
  const { resumeState, resumePreviewMode } = useSelector(
    (state) => ({
      resumeState: state.resume,
      resumePreviewMode: state.resume.preview,
    }),
    shallowEqual
  );
  const dispatch = useDispatch();

  const classes = makeStyles({
    tab: {
      borderRadius: "6px 6px 0 0",
      margin: "0 1px",
    },
    indicator: {
      backgroundColor: "transparent",
    },
    selectedTab: {
      backgroundColor: "white",
    },
    tabRoot: {
      backgroundColor: "#f8fdff",
    },
  })();

  const handleTabChange = (event, newValue) => {
    dispatch(resumeActions.selectTab({ value: newValue }));
  };

  const tabs = [
    { title: "Select Resume", component: <SelectResume /> },
    { title: "Personal Info", component: <PersonalInfo /> },
    { title: "Work History", component: <WorkHistory /> },
    { title: "Education", component: <Educations /> },
    { title: "Skills", component: <Skills /> },
  ]; 

  return (
    <>
      <TabContext value={resumeState.tabValue}>
        {/* Tab Headers */}
        <Tabs
          value={resumeState.tabValue}
          onChange={handleTabChange}
          textColor="primary"
          classes={{ indicator: classes.indicator }}
          variant="fullWidth"
          orientation="horizontal"
        >
          {tabs.map((tab, tabIndex) => {
            return (
              <Tab
                key={tabIndex}
                className={classes.tab}
                value={tabIndex.toString()}
                classes={{
                  root:
                    tabIndex.toString() === resumeState.tabValue
                      ? classes.selectedTab
                      : classes.tabRoot,
                }}
                label={
                  <TabHeader
                    selected={resumeState.tabValue}
                    index={tabIndex}
                    title={tab.title}
                  />
                }
              ></Tab>
            );
          })}
        </Tabs>
        {/* TabContent */}
        <Paper square style={{ margin: "0 1px" }}>
          {tabs.map((tab, index) => {
            return (
              <TabPanel key={index} value={index.toString()}>
                {tab.component}
              </TabPanel>
            );
          })}
        </Paper>
      </TabContext>
      {resumePreviewMode ? <ResumePreview /> : ""}
    </>
  );
};
