import React, { useEffect } from "react";
import { Box } from "@material-ui/core";
import { ResumeBuilder } from "../modules/Resume/pages/ResumeBuilder";
// import { AuthDialog } from "./authDialog";
import { gql, useQuery } from "@apollo/client";
import { Redirect } from "react-router-dom";
import { useParams } from "react-router";
import resumeList from "../modules/Resume/_redux/resumeTemplates";
// Redux imports
import { useDispatch } from "react-redux";
// import { resumeActions } from "../_redux/resumeActions";
import { resumeActions } from "../modules/Resume/_redux/resumeActions";

const EDIT_RESUME = gql`
  query Query($getResumeOfUserResumeId: String!) {
    getResumeOfUser(resumeId: $getResumeOfUserResumeId) {
      resume {
        data
        name
        resumeName
        _id
        updatedAt
        createdAt
      }
    }
  }
`;

export const ResumeEditPage = (props) => {
  console.log(
    "🚀 ~ file: ResumeEditPage.js ~ line 28 ~ ResumeEditPage ~ props",
    props
  );
  let { resumeId: id } = useParams();
  console.log(id);
  const dispatch = useDispatch();
  const { data } = useQuery(EDIT_RESUME, {
    variables: {
      getResumeOfUserResumeId: id,
    },
    onCompleted() {
      console.log(data);
      let resumeData = JSON.parse(data.getResumeOfUser.resume.data);
      dispatch(resumeActions.updateResumeData(resumeData));
      dispatch(resumeActions.setResumeId(id));
      let themeIndex = 2;
      resumeList.map((resumes, index) => {
        if (data.getResumeOfUser.resume.resumeName === resumes.identifier)
          themeIndex = index;
      });
      dispatch(resumeActions.selectedResume(themeIndex));
    },
  });

  return (
    <Box>
      {id === null ? (
        <Redirect exact to="/dashboard" />
      ) : (
        <div>
          <ResumeBuilder />
        </div>
      )}
    </Box>
  );
};
