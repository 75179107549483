import {
  gql,
} from "@apollo/client";

import client from "../../../../apolloClient";

export function getUserByToken() {
  console.log("getting user");
  const GET_PROFILE = gql`
    query Profile {
      me {
        id
        firstName
        lastName
        email
        profileImage
        newEmail
        isEmailVerified
      }
    }
  `;
  console.log("making a query");
  return client.query({
    query: GET_PROFILE,
  });
}
