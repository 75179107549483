import React from "react";
import {
  Typography,
  Box,
  colors,
  Grid,
  TextField,
  makeStyles,
} from "@material-ui/core";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";

import BottomNav from "./BottomNav";

import { resumeActions } from "../_redux/resumeActions";

export default () => {
  const classes = makeStyles({
    heading: {
      color: colors.grey[700],
    },
  })();

  const dispatch = useDispatch();

  const { resumeData } = useSelector(
    (state) => ({
      resumeData: state.resume.resumeData,
    }),
    shallowEqual
  );

  const [fname, lname] = resumeData.basics.name.split(" ");

  const initialPersonalInfo = {
    firstName: fname || "",
    lastName: lname || "",
    phone: resumeData.basics.phone,
    email: resumeData.basics.email,
    addressLine1: resumeData.basics.location.address,
    addressLine2: "address2",
    postcode: resumeData.basics.location.postalCode,
    city: resumeData.basics.location.city,
    state: "State",
    country: "Country 1",
  };

  const personalInfoSchema = Yup.object().shape({
    firstName: Yup.string("Please enter your first name.").required(),
    lastName: Yup.string("Please enter your last name.").required(),
    phone: Yup.string().required(),
    email: Yup.string()
      .email()
      .required(),
    addressLine1: Yup.string().required(),
    addressLine2: Yup.string(),
    postcode: Yup.string("Please enter your postcode").required(),
    city: Yup.string().required(),
    state: Yup.string().required(),
    country: Yup.string().required(),
  });

  const formik = useFormik({
    initialValues: initialPersonalInfo,
    validationSchema: personalInfoSchema,
    onSubmit: () => {},
  });

  async function validateForm() {
    const res = await formik.validateForm();
    if (Object.keys(res).length === 0) {
      dispatch(resumeActions.updatePersonalInfo(formik.values));
      let data = {
        basics: {}
      }
      const u = formik.values;
      // console.log(u, 'u');
      data.basics.name = `${u.firstName} ${u.lastName}`;
      data.basics.phone = u.phone;
      data.basics.email = u.email;
      data.basics.location = {
        address: u.addressLine1,
        postalCode: u.postcode,
        city: u.city,
      };
      return data;
    }
    formik.submitForm();
    return false;
  }

  function getHelperText(field, initialHelper) {
    return formik.touched[field] && formik.errors[field]
      ? formik.errors[field]
      : initialHelper && "";
  }

  const isInputError = (field) => formik.touched[field] && formik.errors[field];

  return (
    <Box>
      <Typography variant="h5" classes={{ h5: classes.heading }}>
        Personal Information
      </Typography>
      <form>
        <Box mt={3}>
          <Grid container spacing={6} xs={12} justify="space-between">
            {/* Left Column */}
            <Grid item xs={12} md={6} justify="space-between">
              <TextField
                className="mb-5"
                label="First Name"
                variant="outlined"
                error={isInputError("firstName")}
                helperText={getHelperText("firstName")}
                fullWidth={true}
                {...formik.getFieldProps("firstName")}
              />
              <TextField
                className="mb-5"
                label="Last Name"
                variant="outlined"
                fullWidth={true}
                error={isInputError("lastName")}
                helperText={getHelperText("lastName")}
                {...formik.getFieldProps("lastName")}
              />
              <Grid container spacing={2} justify="space-between">
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Phone"
                    variant="outlined"
                    fullWidth={true}
                    error={isInputError("phone")}
                    helperText={getHelperText("phone")}
                    {...formik.getFieldProps("phone")}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Email"
                    variant="outlined"
                    fullWidth={true}
                    error={isInputError("email")}
                    helperText={getHelperText("email")}
                    {...formik.getFieldProps("email")}
                  />
                </Grid>
              </Grid>
            </Grid>
            {/* Right Column */}
            <Grid item xs={12} md={6}>
              {/* Address 1 */}
              <TextField
                className="mb-5"
                label="Address Line 1"
                variant="outlined"
                fullWidth={true}
                error={isInputError("addressLine1")}
                helperText={getHelperText("addressLine1")}
                {...formik.getFieldProps("addressLine1")}
              />
              {/* Address 2 */}
              <TextField
                className="mb-5"
                label="Address Line 2"
                variant="outlined"
                fullWidth={true}
              />
              {/* PostCode and City */}
              <Grid container spacing={2} justify="space-between">
                <Grid item xs={12} sm={6}>
                  <TextField
                    className="mb-5"
                    label="Postcode"
                    variant="outlined"
                    fullWidth={true}
                    error={isInputError("postcode")}
                    helperText={getHelperText("postcode")}
                    {...formik.getFieldProps("postcode")}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    className="mb-5"
                    label="City"
                    variant="outlined"
                    fullWidth={true}
                    error={isInputError("city")}
                    helperText={getHelperText("city")}
                    {...formik.getFieldProps("city")}
                  />
                </Grid>
              </Grid>
              {/* State and Country */}
              <Grid container spacing={2} justify="space-between">
                <Grid item xs={12} sm={6}>
                  <TextField
                    className="mb-5"
                    label="State"
                    variant="outlined"
                    fullWidth={true}
                    error={isInputError("state")}
                    helperText={getHelperText("state")}
                    {...formik.getFieldProps("state")}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    className="mb-5"
                    label="Country"
                    variant="outlined"
                    fullWidth={true}
                    error={isInputError("country")}
                    helperText={getHelperText("country")}
                    {...formik.getFieldProps("country")}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </form>
      <Box mt={5}>
        <BottomNav validate={validateForm} />
      </Box>
    </Box>
  );
};
