import React from "react";

import {
  GridList,
  GridListTile,
  Box,
  Typography,
  colors,
  IconButton,
  GridListTileBar,
  Icon,
  makeStyles,
  Grid,
} from "@material-ui/core";

// Redux imports
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { resumeActions } from "../_redux/resumeActions";

import { toAbsoluteUrl } from "../../../../_metronic/_helpers/AssetsHelpers";
import BottomNav from "./BottomNav";

const useStyles = makeStyles((theme) => ({
  heading: {
    color: colors.grey[700],
  },
  iconRoot: {
    color: "white",
  },
  selectedResumeTitleBar: {
    backgroundColor: theme.palette.primary.main,
  },
  selectedResumeTile: {
    border: "1px solid",
    borderColor: theme.palette.primary.main,
  },
}));

export default () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { resumeState } = useSelector(
    (state) => ({ resumeState: state.resume }),
    shallowEqual
  );

  function handleResumeSelect(value) {
    dispatch(resumeActions.selectResume({ value }));
    // dispatch(resumeActions.nextTab());
  }

  return (
    <>
      <Typography
        variant="h5"
        className="mb-5"
        classes={{ h5: classes.heading }}
      >
        Select Resume
      </Typography>
      <Box mt={4}>
        {/* <GridList cellHeight={300} xs={1} cols={4} xl={4} spacing={60}>
          {resumeState.resumeList.map((r, index) => (
            <GridListTile
              key={r.file}
              cols={1}
              classes={
                resumeState.selectedResume === index
                  ? { tile: classes.selectedResumeTile }
                  : {}
              }
              onClick={() => {
                handleResumeSelect(index);
                dispatch(resumeActions.setPreview(true));
              }}
            >
              <img
                style={{ cursor: "pointer" }}
                src={toAbsoluteUrl("/media/resume/" + r.file)}
                alt={r.title}
              />
              <GridListTileBar
                title={r.title}
                classes={
                  resumeState.selectedResume === index
                    ? { root: classes.selectedResumeTitleBar }
                    : {}
                }
                actionIcon={
                  <IconButton>
                    <Icon classes={{ root: classes.iconRoot }}>
                      {resumeState.selectedResume === index
                        ? "check_circle_outline"
                        : "radio_button_unchecked"}
                    </Icon>
                  </IconButton>
                }
              />
            </GridListTile>
          ))}
        </GridList> */}

        <Grid container direction="row" spacing={7}>
          {resumeState.resumeList.map((r, index) => (
            <Grid item xs={12} lg={3} sm={4}>
              <GridList cellHeight={300} cols={1}>
                <GridListTile
                  key={r.file}
                  cols={1}
                  classes={
                    resumeState.selectedResume === index
                      ? { tile: classes.selectedResumeTile }
                      : {}
                  }
                  onClick={() => {
                    // handleResumeSelect(index);
                    // dispatch(resumeActions.setPreview(true));
                  }}
                >
                  <img
                    style={{ cursor: "pointer" }}
                    src={toAbsoluteUrl("/media/resume/" + r.file)}
                    alt={r.title}
                    onClick={()=>{
                      // alert('yes')
                      handleResumeSelect(index);
                      dispatch(resumeActions.setPreview(true));
                    }}
                  />
                  <GridListTileBar
                    title={r.title}
                    onClick={()=>{
                      handleResumeSelect(index);
                    }}
                    style={{ cursor: 'pointer' }}
                    classes={
                      resumeState.selectedResume === index
                        ? { root: classes.selectedResumeTitleBar }
                        : {}
                    }
                    actionIcon={
                      <IconButton>
                        <Icon classes={{ root: classes.iconRoot }}>
                          {resumeState.selectedResume === index
                            ? "check_circle_outline"
                            : "radio_button_unchecked"}
                        </Icon>
                      </IconButton>
                    }
                  />
                </GridListTile>
              </GridList>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box mt={5}>
        <BottomNav />
      </Box>
    </>
  );
};
