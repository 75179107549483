import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
} from "@material-ui/core";

import { resumeActions } from "../../_redux/resumeActions";

export default (props) => {
  const { formik, index } = props;

  const dispatch = useDispatch();

  const { showIdeasFor, copiedIdeas, showIdeasDialog } = useSelector(
    (state) => ({
      showIdeasFor: state.resume.showIdeasFor,
      copiedIdeas: state.resume.copiedIdeas,
      showIdeasDialog: state.resume.showIdeasDialog,
    })
  );

  if (showIdeasFor === index && !showIdeasDialog) {
    if (formik.values.workExperienceList[index].jobDescription.length) {
      formik.values.workExperienceList[index].jobDescription += "\n\n";
    }

    formik.values.workExperienceList[index].jobDescription += copiedIdeas;
    dispatch(resumeActions.setShowIdeasFor(-1));
    dispatch(resumeActions.setCopiedIdeas(""));
  }

  function getHelperTextForArray(
    formik,
    arrayKey,
    index,
    fieldKey,
    initialHelper
  ) {
    const helperText =
      formik.touched &&
      formik.touched[arrayKey] &&
      formik.touched[arrayKey][index] &&
      formik.touched[arrayKey][index][fieldKey] &&
      formik.errors &&
      formik.errors[arrayKey] &&
      formik.errors[arrayKey][index] &&
      formik.errors[arrayKey][index][fieldKey]
        ? formik.errors[arrayKey][index][fieldKey]
        : initialHelper && "";
    return helperText;
  }

  const isInputErrorForArray = (formik, arrayKey, index, fieldKey) => {
    const isError =
      formik.touched &&
      formik.touched[arrayKey] &&
      formik.touched[arrayKey][index] &&
      formik.touched[arrayKey][index][fieldKey] &&
      formik.errors &&
      formik.errors[arrayKey] &&
      formik.errors[arrayKey][index] &&
      formik.errors[arrayKey][index][fieldKey]
        ? true
        : false;
    return isError;
  };

  return (
    <>
      <Box>
        <Grid container spacing={4} justify="space-between">
          <Grid item xs={12} sm={4}>
            <TextField
              name={`workExperienceList[${index}].companyName`}
              label="Company Name"
              helperText={getHelperTextForArray(
                formik,
                "workExperienceList",
                index,
                "companyName"
              )}
              error={isInputErrorForArray(
                formik,
                "workExperienceList",
                index,
                "companyName"
              )}
              variant="outlined"
              {...formik.getFieldProps(
                `workExperienceList[${index}].companyName`
              )}
              fullWidth={true}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label="Company website"
              variant="outlined"
              fullWidth={true}
              name={`workExperienceList[${index}].companyWebsite`}
              helperText={getHelperTextForArray(
                formik,
                "workExperienceList",
                index,
                "companyWebsite"
              )}
              error={isInputErrorForArray(
                formik,
                "workExperienceList",
                index,
                "companyWebsite"
              )}
              {...formik.getFieldProps(
                `workExperienceList[${index}].companyWebsite`
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label="Job Position"
              variant="outlined"
              fullWidth={true}
              name={`workExperienceList[${index}].jobPosition`}
              helperText={getHelperTextForArray(
                formik,
                "workExperienceList",
                index,
                "jobPosition"
              )}
              error={isInputErrorForArray(
                formik,
                "workExperienceList",
                index,
                "jobPosition"
              )}
              {...formik.getFieldProps(
                `workExperienceList[${index}].jobPosition`
              )}
            />
          </Grid>
        </Grid>
      </Box>
      <Box mt={5}>
        <Grid container spacing={4} justify="space-between">
          <Grid item xs={12} md={4}>
            <Grid container spacing={2} justify="space-between">
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Start Date"
                  type="date"
                  variant="outlined"
                  fullWidth={true}
                  helperText={getHelperTextForArray(
                    formik,
                    "workExperienceList",
                    index,
                    "startDate"
                  )}
                  error={isInputErrorForArray(
                    formik,
                    "workExperienceList",
                    index,
                    "startDate"
                  )}
                  {...formik.getFieldProps(
                    `workExperienceList[${index}].startDate`
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid
                  container
                  spacing={4}
                  direction="column"
                  justify="space-between"
                >
                  <Grid item>
                    <TextField
                      label="End Date"
                      type="date"
                      variant="outlined"
                      fullWidth={true}
                      helperText={getHelperTextForArray(
                        formik,
                        "workExperienceList",
                        index,
                        "endDate"
                      )}
                      error={isInputErrorForArray(
                        formik,
                        "workExperienceList",
                        index,
                        "endDate"
                      )}
                      disabled={
                        formik.values.workExperienceList[index].isWorking
                      }
                      {...formik.getFieldProps(
                        `workExperienceList[${index}].endDate`
                      )}
                    />
                    <FormControlLabel
                      control={<Checkbox />}
                      label="Working here"
                      {...formik.getFieldProps(
                        `workExperienceList[${index}].isWorking`
                      )}
                    />
                  </Grid>
                  <Grid item className="text-right d-none d-sm-block" xs={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      className="text-white "
                      fullWidth={true}
                      onClick={() => {
                        dispatch(resumeActions.setShowIdeasDialog(true));
                        dispatch(resumeActions.setShowIdeasFor(index));
                      }}
                    >
                      Show Ideas
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={8}>
            <Grid container>
              <Grid item xs={12}>
                <TextField
                  label="Job Description"
                  multiline
                  rows={10}
                  variant="outlined"
                  fullWidth={true}
                  helperText={getHelperTextForArray(
                    formik,
                    "workExperienceList",
                    index,
                    "jobDescription"
                  )}
                  error={isInputErrorForArray(
                    formik,
                    "workExperienceList",
                    index,
                    "jobDescription"
                  )}
                  {...formik.getFieldProps(
                    `workExperienceList[${index}].jobDescription`
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
