import React, { Suspense } from "react";
import { Redirect, Switch } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
// import { BuilderPage } from "./pages/BuilderPage";
import { DashboardPage } from "./pages/DashboardPage";
import { ResumeEditPage } from "./pages/ResumeEditPage";

import LoginRedirect from "./modules/Auth/pages/LoginRedirect";

export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <ContentRoute path="/dashboard" component={DashboardPage} />
        <ContentRoute path="/resume/:resumeId" component={ResumeEditPage} />

        {/* <ContentRoute path="/builder" component={BuilderPage} /> */}
        <ContentRoute path="/auth/login" component={LoginRedirect} />
        <Redirect to="/error/error-v1" />
      </Switch>
    </Suspense>
  );
}
