import {
  Grid,
  Typography,
  Box,
  colors,
  useTheme,
  makeStyles,
} from "@material-ui/core";

import React from "react";

export default (props) => {
  const { index, title, selected } = props;
  const theme = useTheme();
  const gray = colors.blueGrey[300];
  const classes = makeStyles({
    subtitle1: {
      textTransform: "none",
      fontWeight: "bold !important",
      color: index.toString() === selected ? theme.palette.primary.main : gray,
    },
    stepNoBox: {
      color: index.toString() === selected ? "white" : colors.lightBlue[500],
      backgroundColor:
        index.toString() === selected
          ? colors.lightBlue[500]
          : colors.lightBlue[50],
    },
  })();

  return (
    <Box color="primary" p={1.4}>
      <Grid container alignItems="center" spacing={2}>
        <Grid item>
          <Box px={1.8} py={0.5} borderRadius={6} className={classes.stepNoBox}>
            <Typography variant="h6" className="font-weight-bold">
              {index + 1}
            </Typography>
          </Box>
        </Grid>
        <Grid item>
          <Grid container direction="column">
            <Grid item>
              <Typography
                align="left"
                variant="subtitle1"
                color="secondary"
                className="font-weight-bold"
                classes={{ subtitle1: classes.subtitle1 }}
              >
                {title}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
