import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Button,
  Typography,
  CircularProgress,
  Snackbar,
} from "@material-ui/core";
import { useQuery, gql, useLazyQuery, useMutation } from "@apollo/client";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import MuiAlert from "@material-ui/lab/Alert";

import { resumeActions } from "../_redux/resumeActions";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const SAVE_RESUME = gql`
  mutation SaveResumeMutation(
    $saveResumeResumeName: String!
    $saveResumeResumeData: String!
    $saveResumeResumeId: String
  ) {
    saveResume(
      resumeName: $saveResumeResumeName
      resumeData: $saveResumeResumeData
      resumeId: $saveResumeResumeId
    ) {
      code
      message
      success
      resumeId
    }
  }
`;

export default (props) => {
  const { validate } = props;
  const [open, setOpen] = React.useState(false);
  const [resumeSaveLoader, setResumeSaveLoader] = React.useState(false);
  const [snackBarText, setSnackBarText] = React.useState("saved successfully");

  const { resumeState, resumePreviewLoader } = useSelector(
    (state) => ({
      resumeState: state.resume,
      resumePreviewLoader: state.resume.resumePreviewLoader,
    }),
    shallowEqual
  );
  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  

  const dispatch = useDispatch();

  const {
    resumeId,
    resumeData,
    currentTab,
    selectedResume,
    sampleResumeData,
    savedResumeHash,
    user,
    preview,
  } = useSelector((state) => ({
    resumeId: state.resume.resumeId,
    selectedResume:
      state.resume.resumeList[state.resume.selectedResume].identifier,
    currentTab: state.resume.tabValue,
    resumeData: state.resume.resumeData,
    sampleResumeData: state.resume.sampleResumeData,
    savedResumeHash: state.resume.savedResumeHash,
    user: state.auth.user,
    preview: state.resume.preview,
  }));

  useEffect(()=>{
    console.log(resumeData);
  }, [resumeData])

  const [saveResume, { loading: saveResumeLoading }] = useMutation(
    SAVE_RESUME,
    {
      async onCompleted(data) {
        const { code, resumeId } = data.saveResume;
        if (code === "200") {
          dispatch(resumeActions.setResumeId(resumeId));
          if (validate) {
            const isOk = await validate();
            
            isOk &&
              resumeState.tabValue !== "4" &&
              dispatch(resumeActions.nextTab());

            // if (resumeState.tabValue === "4") setOpen(true);
          } else {
            resumeState.tabValue !== "4" &&
              dispatch(resumeActions.nextTab());
            // if (resumeState.tabValue === "4") setOpen(true);
          }
          // const hash = crypto
          //   .createHash("sha256")
          //   .update(JSON.stringify(resumeData))
          //   .digest("hex");
          // setSnackBarText("Resume saved to dashboard");
          // setOpen(true);
          
          // dispatch(resumeActions.setSavedResumeHash(hash));
        }
      },
    }
  );

  return (
    <Box>
      <Grid container justify="space-between" wrap="nowrap">
        <Grid item>
          {resumeState.tabValue === "0" ? (
            ""
          ) : (
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={() => {
                dispatch(resumeActions.previousTab());
              }}
            >
              <Typography
                align="center"
                style={{ color: "white", width: "56px", height: "20px" }}
              >
                Back
              </Typography>
            </Button>
          )}
        </Grid>
        <Grid item>
          <Grid container spacing="2">
            <Grid item>
              {resumeState.tabValue !== "0" ? (
                <Button
                  variant="outlined"
                  color="secondary"
                  size="large"
                  onClick={async () => {
                    if (validate) {
                      const isOk = await validate();
                      if(isOk){
                        console.log({
                          ...resumeData,
                          ...isOk
                        }, 'updated resume')
                        saveResume({
                          variables: {
                            saveResumeResumeId: resumeId,
                            saveResumeResumeName: selectedResume,
                            saveResumeResumeData: JSON.stringify({
                              ...resumeData,
                              ...isOk
                            }),
                          },
                        })
                        dispatch(resumeActions.setPreview(true));
                      }
                      dispatch(resumeActions.setResumePreviewLoader(true));
                    } else {
                      dispatch(resumeActions.setPreview(true));
                      dispatch(resumeActions.setResumePreviewLoader(true));
                      saveResume({
                        variables: {
                          saveResumeResumeId: resumeId,
                          saveResumeResumeName: selectedResume,
                          saveResumeResumeData: JSON.stringify(resumeData),
                        },
                      })
                    }
                  }}
                >
                  {resumePreviewLoader ? (
                    <CircularProgress
                      color="secondary"
                      style={{ marginLeft: "50px", marginRight: "50px" }}
                      size={20}
                    />
                  ) : resumeState.tabValue === "4" ? (
                    <Typography
                      align="center"
                      style={{ width: "120px", height: "20px" }}
                    >
                      <b>Preview & Save</b>
                    </Typography>
                  ) : (
                    <Typography
                      align="center"
                      style={{ width: "120px", height: "20px" }}
                    >
                      <b>Preview</b>
                    </Typography>
                  )}
                </Button>
              ) : (
                ""
              )}
            </Grid>
            <Grid item>
              {resumeState.tabValue === "4" ? (
                <> </>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={async () => {
                    if(resumeState.tabValue === "0"){
                      dispatch(resumeActions.nextTab());
                      return ;
                    } 
                    if(validate){
                      const isOk = await validate();
                      if(isOk){
                        console.log({
                          ...resumeData,
                          ...isOk
                        }, 'updated resume')
                        saveResume({
                          variables: {
                            saveResumeResumeId: resumeId,
                            saveResumeResumeName: selectedResume,
                            saveResumeResumeData: JSON.stringify({
                              ...resumeData,
                              ...isOk
                            }),
                          },
                        })
                      }
                    }else{
                      saveResume({
                        variables: {
                          saveResumeResumeId: resumeId,
                          saveResumeResumeName: selectedResume,
                          saveResumeResumeData: JSON.stringify(resumeData),
                        },
                      })
                    }
                  }}
                >
                  {" "}
                  {resumeSaveLoader || saveResumeLoading ? (
                    <CircularProgress
                      color={"inherit"}
                      style={{ marginLeft: "50px", marginRight: "50px" }}
                      size={20}
                    />
                  ) : (
                    <Typography
                      align="center"
                      style={{ color: "white", width: "120px", height: "20px" }}
                    >
                      {resumeState.tabValue === "0"
                        ? "Continue"
                        : resumeState.tabValue === "4"
                        ? "Save"
                        : "Save & Continue"}
                    </Typography>
                  )}
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Snackbar
        open={open}
        autoHideDuration={5000}
        onClose={handleSnackBarClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <Alert
          style={{ fontSize: "15px" }}
          onClose={handleSnackBarClose}
          severity="success"
        >
          {snackBarText}
        </Alert>
      </Snackbar>
    </Box>
  );
};
