import React, { useState } from "react";

import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  makeStyles,
  Divider,
} from "@material-ui/core";

import { useSelector } from "react-redux";

export function AuthDialog() {
  const { user } = useSelector((state) => state.auth);

  const classes = makeStyles({
    dialog: {
      position: "absolute",
      top: 10,
    },
  })();

  const [openAuthDialog, setOpenAuthDialog] = useState(user ? false : true);

  const handleClose = () => {
    setOpenAuthDialog(false);
  };

  function authenticate() {
    window.location.href =
      process.env.REACT_APP_LOGIN_URL + "/#/login?redirect=resumebuilder";
    return;
  }

  return (
    <Box>
      {!user && (
        <Box>
          <Dialog
            classes={{
              paper: classes.dialog,
            }}
            open={openAuthDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            maxWidth="xs"
          >
            <DialogTitle
              id="alert-dialog-title"
              style={{ textAlign: "center", color: "#3783e7" }}
            >
              Get more features from this site by logging in
            </DialogTitle>
            <Divider />

            <DialogContent>
              <DialogContentText component="div">
                <Box textAlign="center" mt={4}>
                  It only takes a few seconds for this process!
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  width="100%"
                  mt={3}
                >
                  <Box>
                    <Button
                      variant="contained"
                      style={{ color: "white" }}
                      color="primary"
                      onClick={() => {
                        authenticate();
                      }}
                    >
                      Create Account
                    </Button>
                  </Box>
                  <Box mx={2}>OR</Box>
                  <Box>
                    <Button
                      autoFocus
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        authenticate();
                      }}
                    >
                      Login
                    </Button>
                  </Box>
                </Box>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Close</Button>
            </DialogActions>
          </Dialog>
        </Box>
      )}
    </Box>
  );
}
