import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { gql, useLazyQuery } from "@apollo/client";
import SearchBar from "material-ui-search-bar";
import Highlighter from "react-highlight-words";
import {
  Modal,
  Card,
  makeStyles,
  Grid,
  Box,
  List,
  ListItem,
  ListItemText,
  Divider,
  ListItemIcon,
  Checkbox,
  Button,
  CardContent,
  CircularProgress,
  IconButton,
  CardHeader,
} from "@material-ui/core";
import { CloseRounded } from "@material-ui/icons";

import { resumeActions } from "../../_redux/resumeActions";
// import getJobIdeas from "./searchJobIdeas";

const SEARCH_JOB_IDEAS = gql`
  query Query($searchJobIdeasSearch: String!) {
    searchJobIdeas(search: $searchJobIdeasSearch) {
      title
      jobTitleSamples
      occupationId
      workActivities {
        id
        description
      }
      detailedWorkActivities {
        name
      }
    }
  }
`;

export default () => {
  const classes = makeStyles((theme) => ({
    previewCard: {
      position: "absolute",
      width: "60%",
      height: "95%",
      backgroundColor: "whitesmoke",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
    copyButton: {
      position: "absolute",
      right: "25px",
    },
    highlight: {
      backgroundColor: "#ffd63f",
    },
    cardAction: {
      display: "flex",
      alignSelf: "center",
    },
  }))();
  const dispatch = useDispatch();

  const [searchValue, setSearchValue] = React.useState("");
  const [selectedOccupation, setSelectedOccupation] = React.useState(-1);

  const { showIdeasDialog } = useSelector((state) => ({
    showIdeasDialog: state.resume.showIdeasDialog,
  }));

  // const searchJobIdeasData = getJobIdeas();
  // const searchJobIdeasCalled = true;

  const [selectedIdeas, setSelectedIdeas] = React.useState([]);
  const [selectedIdeasId, setSelectedIdeasId] = React.useState([]);

  const selectJobIdea = (id, value) => {
    setSelectedIdeasId([...selectedIdeasId, id]);
    return setSelectedIdeas([...selectedIdeas, value]);
  };

  const deselectJobIdea = (id, value) => {
    setSelectedIdeasId(selectedIdeasId.filter((i) => i !== id));
    return setSelectedIdeas(selectedIdeas.filter((i) => i !== value));
  };

  const [
    searchJobIdeas,
    {
      called: searchJobIdeasCalled,
      loading: searchJobIdeasLoading,
      data: searchJobIdeasData,
    },
  ] = useLazyQuery(SEARCH_JOB_IDEAS);

  function handleClose() {
    setSelectedIdeas([]);
    dispatch(resumeActions.setShowIdeasDialog(false));
  }

  return (
    <>
      <Modal open={showIdeasDialog} onClose={handleClose}>
        <Card className={classes.previewCard}>
          <CardHeader
            classes={{ action: classes.cardAction }}
            title={
              <SearchBar
                className="mr-5"
                value={searchValue}
                onChange={setSearchValue}
                onRequestSearch={() => {
                  searchJobIdeas({
                    variables: {
                      searchJobIdeasSearch: searchValue,
                    },
                  });
                }}
              />
            }
            action={
              <Box display="flex">
                <Button
                  className="mr-5 mt-2"
                  variant="contained"
                  color="primary"
                  disabled={selectedIdeas.length === 0}
                  size="large"
                  onClick={() => {
                    // navigator.clipboard.writeText(
                    //   "• " + selectedIdeas.join("\n• ")
                    // );
                    dispatch(
                      resumeActions.setCopiedIdeas(selectedIdeas.join("\n\n"))
                    );
                    handleClose();
                  }}
                >
                  Copy
                </Button>
                <IconButton
                  className="mt-2"
                  size="medium"
                  onClick={() =>
                    dispatch(resumeActions.setShowIdeasDialog(false))
                  }
                >
                  <CloseRounded />
                </IconButton>
              </Box>
            }
          />
          <CardContent>
            <Grid
              container
              spacing={4}
              justify="space-around"
              alignItems="center"
            >
              <Grid item xs={12}>
                <Grid
                  container
                  spacing={2}
                  justify="center"
                  alignItems="center"
                >
                  {!searchJobIdeasCalled ? (
                    <Grid item xs={12} className="text-center">
                      Search Occupation
                    </Grid>
                  ) : searchJobIdeasLoading ? (
                    <Grid item xs={12} className="text-center">
                      <CircularProgress style={{ color: "#3699FF" }} />
                    </Grid>
                  ) : (
                    <>
                      <Grid item x s={12} md={5}>
                        <Box height="82vh" style={{ overflowX: "auto" }}>
                          <List>
                            {searchJobIdeasData.searchJobIdeas.map(
                              (o, index) => {
                                return (
                                  <>
                                    <ListItem
                                      button
                                      selected={selectedOccupation === index}
                                      key={o.occupationId}
                                      onClick={() =>
                                        setSelectedOccupation(index)
                                      }
                                    >
                                      <ListItemText
                                        primary={
                                          <Highlighter
                                            autoEscape={false}
                                            highlightClassName={
                                              classes.highlight
                                            }
                                            searchWords={[searchValue]}
                                            textToHighlight={o.title}
                                          />
                                        }
                                        secondary={
                                          <Highlighter
                                            highlightClassName={
                                              classes.highlight
                                            }
                                            searchWords={[searchValue]}
                                            textToHighlight={o.jobTitleSamples.join(
                                              ", "
                                            )}
                                          />
                                        }
                                      />
                                    </ListItem>
                                    <Divider component="li" />
                                  </>
                                );
                              }
                            )}
                          </List>
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={7} className="text-center">
                        {searchJobIdeasLoading || !searchJobIdeasCalled ? (
                          ""
                        ) : selectedOccupation === -1 ? (
                          "Select occupation from list"
                        ) : (
                          <Grid container direction="column" spacing={2}>
                            <Grid item xs={12}>
                              {searchJobIdeasCalled &&
                              !searchJobIdeasLoading &&
                              selectedOccupation !== -1 ? (
                                <Box
                                  height="82vh"
                                  style={{ overflowX: "auto" }}
                                >
                                  <List>
                                    {searchJobIdeasData.searchJobIdeas[
                                      selectedOccupation
                                    ].workActivities.map((w) => {
                                      return (
                                        <ListItem key={w.id}>
                                          <ListItemIcon
                                            onChange={(e) => {
                                              if (e.target.checked) {
                                                selectJobIdea(
                                                  w.id,
                                                  w.description
                                                );
                                              } else {
                                                deselectJobIdea(
                                                  w.id,
                                                  w.description
                                                );
                                              }
                                            }}
                                          >
                                            <Checkbox
                                              edge="end"
                                              disableRipple
                                              checked={selectedIdeasId.includes(
                                                w.id
                                              )}
                                            />
                                          </ListItemIcon>
                                          <ListItemText
                                            style={{ textAlign: "justify" }}
                                          >
                                            {w.description}
                                          </ListItemText>
                                        </ListItem>
                                      );
                                    })}
                                  </List>
                                </Box>
                              ) : (
                                ""
                              )}
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Modal>
    </>
  );
};
