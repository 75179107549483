import React from "react";
import {
  Box,
  Grid,
  TextField,
  Typography,
  makeStyles,
  colors,
  Button,
  Icon,
  IconButton,
  Divider,
  List,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import { Formik, FieldArray } from "formik";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import moment from "moment";
import * as Yup from "yup";

import BottomNav from "./BottomNav";
import { resumeActions } from "../_redux/resumeActions";

function getNewEducation() {
  return {
    institution: "",
    area: "",
    studyType: "",
    startDate: moment().format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  };
}

export default () => {
  let arrayHelpers;

  const useStyles = makeStyles({
    heading: {
      color: colors.grey[700],
    },
    expansionDetails: {
      flexDirection: "column",
    },
  });

  const classes = useStyles();
  const dispatch = useDispatch();

  const { resumeData } = useSelector(
    (state) => ({
      resumeData: state.resume.resumeData,
    }),
    shallowEqual
  );

  function setArrayHelpers(value) {
    arrayHelpers = value;
  }

  const deleteEducation = (index) => {
    arrayHelpers.remove(index);
  };

  const addNewEducation = () => {
    arrayHelpers.push(getNewEducation());
  };

  const initialValues = {
    educations: resumeData.education.map((e) => ({
      institution: e.institution,
      area: e.area,
      courses: e.courses.join(", "),
      studyType: e.studyType,
      startDate: e.startDate || moment().format("YYYY-MM-DD"),
      endDate: e.endDate || moment().format("YYYY-MM-DD"),
      isDoingStudy: false,
    })),
  };

  const validationSchema = Yup.object().shape({
    educations: Yup.array().of(
      Yup.object().shape({
        institution: Yup.string().required("Institution is required"),
        area: Yup.string().required("Area/Field is required"),
        courses: Yup.string(),
        studyType: Yup.string().required("Study type is required"),
        startDate: Yup.string().required() || moment().format("YYYY-MM-DD"),
        endDate: Yup.string().required() || moment().format("YYYY-MM-DD"),
        isDoingStudy: Yup.boolean(),
      })
    ),
  });

  function getHelperTextForArray(
    formik,
    arrayKey,
    index,
    fieldKey,
    initialHelper
  ) {
    const helperText =
      formik.touched &&
      formik.touched[arrayKey] &&
      formik.touched[arrayKey][index] &&
      formik.touched[arrayKey][index][fieldKey] &&
      formik.errors &&
      formik.errors[arrayKey] &&
      formik.errors[arrayKey][index] &&
      formik.errors[arrayKey][index][fieldKey]
        ? formik.errors[arrayKey][index][fieldKey]
        : initialHelper && "";
    return helperText;
  }

  const isInputErrorForArray = (formik, arrayKey, index, fieldKey) => {
    const isError =
      formik.touched &&
      formik.touched[arrayKey] &&
      formik.touched[arrayKey][index] &&
      formik.touched[arrayKey][index][fieldKey] &&
      formik.errors &&
      formik.errors[arrayKey] &&
      formik.errors[arrayKey][index] &&
      formik.errors[arrayKey][index][fieldKey]
        ? true
        : false;
    return isError;
  };

  async function validateForm(formik) {
    const res = await formik.validateForm();
    if (Object.keys(res).length === 0) {
      dispatch(resumeActions.updateEducation(formik.values));
      let data = {
        education: []
      }
      const educations = formik.values.educations;
      data.education = educations.map((e) => ({
        institution: e.institution,
        area: e.area,
        courses: e.courses.split(",").map((i) => i.trim()),
        studyType: e.studyType,
        startDate: e.startDate,
        endDate: e.isDoingStudy ? "" : e.endDate,
      }));
      return data;
    }
    formik.submitForm();
    return false;
  }

  return (
    <Box>
      <Typography variant="h5" classes={{ h5: classes.heading }}>
        Education
      </Typography>
      <Formik initialValues={initialValues} validationSchema={validationSchema}>
        {(formik) => {
          return (
            <>
              <List>
                <FieldArray
                  name="educations"
                  render={(arrayHelpers) => {
                    setArrayHelpers(arrayHelpers);
                    return formik.values.educations.map((_, index) => (
                      <Box mt={3}>
                        <Box>
                          <Grid container direction="column" spacing={3}>
                            <Grid item container xs={12} spacing={2}>
                              <Grid item xs={12} md={4}>
                                <TextField
                                  variant="outlined"
                                  label="Institution"
                                  fullWidth={true}
                                  name={`educations[${index}].institution`}
                                  helperText={getHelperTextForArray(
                                    formik,
                                    "educations",
                                    index,
                                    "institution"
                                  )}
                                  error={isInputErrorForArray(
                                    formik,
                                    "educations",
                                    index,
                                    "institution"
                                  )}
                                  {...formik.getFieldProps(
                                    `educations[${index}].institution`
                                  )}
                                />
                              </Grid>
                              <Grid item xs={12} md={4}>
                                <TextField
                                  variant="outlined"
                                  label="Study type"
                                  fullWidth={true}
                                  name={`educations[${index}].studyType`}
                                  helperText={getHelperTextForArray(
                                    formik,
                                    "educations",
                                    index,
                                    "studyType"
                                  )}
                                  error={isInputErrorForArray(
                                    formik,
                                    "educations",
                                    index,
                                    "studyType"
                                  )}
                                  {...formik.getFieldProps(
                                    `educations[${index}].studyType`
                                  )}
                                />
                              </Grid>
                              <Grid item xs={12} md={4}>
                                <TextField
                                  variant="outlined"
                                  label="Area / Field"
                                  fullWidth={true}
                                  name={`educations[${index}].area`}
                                  helperText={getHelperTextForArray(
                                    formik,
                                    "educations",
                                    index,
                                    "area"
                                  )}
                                  error={isInputErrorForArray(
                                    formik,
                                    "educations",
                                    index,
                                    "area"
                                  )}
                                  {...formik.getFieldProps(
                                    `educations[${index}].area`
                                  )}
                                />
                              </Grid>
                            </Grid>
                            <Grid item container xs={12} spacing={2}>
                              <Grid item xs={12} md={4}>
                                <TextField
                                  variant="outlined"
                                  label="Courses"
                                  fullWidth={true}
                                  name={`educations[${index}].courses`}
                                  helperText={getHelperTextForArray(
                                    formik,
                                    "educations",
                                    index,
                                    "courses"
                                  )}
                                  error={isInputErrorForArray(
                                    formik,
                                    "educations",
                                    index,
                                    "courses"
                                  )}
                                  {...formik.getFieldProps(
                                    `educations[${index}].courses`
                                  )}
                                />
                              </Grid>
                              <Grid item xs={12} md={4}>
                                <TextField
                                  variant="outlined"
                                  label="Start date"
                                  type="date"
                                  fullWidth={true}
                                  name={`educations[${index}].startDate`}
                                  helperText={getHelperTextForArray(
                                    formik,
                                    "educations",
                                    index,
                                    "startDate"
                                  )}
                                  error={isInputErrorForArray(
                                    formik,
                                    "educations",
                                    index,
                                    "startDate"
                                  )}
                                  {...formik.getFieldProps(
                                    `educations[${index}].startDate`
                                  )}
                                />
                              </Grid>
                              <Grid item xs={12} md={4}>
                                <TextField
                                  variant="outlined"
                                  label="End date"
                                  type="date"
                                  fullWidth={true}
                                  disabled={formik.values.educations[index].isDoingStudy}
                                  name={`educations[${index}].endDate`}
                                  helperText={getHelperTextForArray(
                                    formik,
                                    "educations",
                                    index,
                                    "endDate"
                                  )}
                                  error={isInputErrorForArray(
                                    formik,
                                    "educations",
                                    index,
                                    "endDate"
                                  )}
                                  {...formik.getFieldProps(
                                    `educations[${index}].endDate`
                                  )}
                                />
                                <FormControlLabel
                                  control={<Checkbox />}
                                  label="Studying here"
                                  {...formik.getFieldProps(
                                    `educations[${index}].isDoingStudy`
                                  )}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Box>
                        <Box mt={2} align="center">
                          <IconButton
                            onClick={() => {
                              deleteEducation(index);
                            }}
                          >
                            <Delete />
                          </IconButton>
                        </Box>
                        <Box my={2}>
                          <Divider />
                        </Box>
                      </Box>
                    ));
                  }}
                ></FieldArray>
              </List>
              <Box mt={4} display="flex" justifyContent="center">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={addNewEducation}
                >
                  <Icon className="fa fa-plus-circle" />
                  <Box ml={1}>Add Education</Box>
                </Button>
              </Box>
              <Box mt={5}>
                <BottomNav validate={() => validateForm(formik)} />
              </Box>
            </>
          );
        }}
      </Formik>
    </Box>
  );
};
