import React from "react";
import {
  Box,
  Typography,
  makeStyles,
  colors,
  Grid,
  TextField,
  Button,
  Icon,
  IconButton,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import * as Yup from "yup";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Formik, FieldArray } from "formik";

import BottomNav from "./BottomNav";
import { resumeActions } from "../_redux/resumeActions";

export default () => {
  let arrayHelpers;

  const useStyles = makeStyles({
    heading: {
      color: colors.grey[700],
    },
    expansionDetails: {
      flexDirection: "column",
    },
  });
  const classes = useStyles();
  const dispatch = useDispatch();

  const { resumeData } = useSelector(
    (state) => ({ resumeData: state.resume.resumeData }),
    shallowEqual
  );

  const initialValues = {
    skills: resumeData.skills.map((s) => ({
      name: s.name,
      level: s.level,
      keywords: s.keywords.join(", "),
    })),
  };

  const validationSchema = Yup.object().shape({
    skills: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required("Skill name is required"),
        level: Yup.string().required("Skill level is required"),
        keywords: Yup.string().required("Skill keywords are required"),
      })
    ),
  });

  function getNewSkill() {
    const skill = {
      name: "",
      level: "",
      keywords: "",
    };
    return skill;
  }

  function setArrayHelpers(value) {
    arrayHelpers = value;
  }
  const deleteSkill = (index) => {
    arrayHelpers.remove(index);
  };

  const addNewSkill = () => {
    arrayHelpers.push(getNewSkill());
  };

  function getHelperTextForArray(
    formik,
    arrayKey,
    index,
    fieldKey,
    initialHelper
  ) {
    const helperText =
      formik.touched &&
      formik.touched[arrayKey] &&
      formik.touched[arrayKey][index] &&
      formik.touched[arrayKey][index][fieldKey] &&
      formik.errors &&
      formik.errors[arrayKey] &&
      formik.errors[arrayKey][index] &&
      formik.errors[arrayKey][index][fieldKey]
        ? formik.errors[arrayKey][index][fieldKey]
        : initialHelper || "";
    return helperText;
  }

  const isInputErrorForArray = (formik, arrayKey, index, fieldKey) => {
    const isError =
      formik.touched &&
      formik.touched[arrayKey] &&
      formik.touched[arrayKey][index] &&
      formik.touched[arrayKey][index][fieldKey] &&
      formik.errors &&
      formik.errors[arrayKey] &&
      formik.errors[arrayKey][index] &&
      formik.errors[arrayKey][index][fieldKey]
        ? true
        : false;
    return isError;
  };

  async function validateForm(formik) {
    const res = await formik.validateForm();
    if (Object.keys(res).length === 0) {
      dispatch(resumeActions.updateSkills(formik.values));
      let data = {
        skills: []
      }
      const skills = formik.values.skills;
      data.skills = skills.map((s) => ({
        name: s.name,
        level: s.level,
        keywords: s.keywords.split(",").map((k) => k.trim()),
      }));
      return data;
    }
    formik.submitForm();
    return false;
  }

  return (
    <Box>
      <Typography variant="h5" classes={{ h5: classes.heading }}>
        Skills
      </Typography>
      <Formik initialValues={initialValues} validationSchema={validationSchema}>
        {(formik) => {
          return (
            <>
              <FieldArray
                name="skills"
                render={(arrayHelpers) => {
                  setArrayHelpers(arrayHelpers);
                  return formik.values.skills.map((_, index) => (
                    <Box mt={3}>
                      <Grid container xs={12} spacing={3} alignItems="center">
                        <Grid item xs={12} md={3}>
                          <TextField
                            variant="outlined"
                            fullWidth={true}
                            label="Skill name"
                            name={`skills[${index}].name`}
                            helperText={getHelperTextForArray(
                              formik,
                              "skills",
                              index,
                              "name"
                            )}
                            error={isInputErrorForArray(
                              formik,
                              "skills",
                              index,
                              "name"
                            )}
                            {...formik.getFieldProps(`skills[${index}].name`)}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <FormControl
                            variant="outlined"
                            fullWidth={true}
                            size="medium"
                          >
                            <InputLabel htmlFor="skill-level">
                              Skill level
                            </InputLabel>
                            <Select
                              id="skill-level"
                              fullWidth={true}
                              label="Skill level"
                            >
                              <MenuItem value="Beginner">Beginner</MenuItem>
                              <MenuItem value="Intermediate">
                                Intermediate
                              </MenuItem>
                              <MenuItem value="Advance">Advance</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={5}>
                          <TextField
                            variant="outlined"
                            fullWidth={true}
                            label="Skill keywords (e.g. HTML, CSS, JavaScript)"
                            name={`skills[${index}].keywords`}
                            helperText={getHelperTextForArray(
                              formik,
                              "skills",
                              index,
                              "keywords"
                            )}
                            error={isInputErrorForArray(
                              formik,
                              "skills",
                              index,
                              "keywords"
                            )}
                            {...formik.getFieldProps(
                              `skills[${index}].keywords`
                            )}
                          />
                        </Grid>
                        <Grid xs={12} md={1} className="text-center">
                          <IconButton
                            onClick={() => {
                              deleteSkill(index);
                            }}
                          >
                            <Delete />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Box>
                  ));
                }}
              ></FieldArray>
              <Box mt={4} display="flex" justifyContent="center">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={addNewSkill}
                >
                  <Icon className="fa fa-plus-circle" />
                  <Box ml={1}>Add Skill</Box>
                </Button>
              </Box>
              <Box mt={5}>
                <BottomNav validate={() => validateForm(formik)} />
              </Box>
            </>
          );
        }}
      </Formik>
    </Box>
  );
};
