/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";

import { Button } from "@material-ui/core";

import Dropdown from "react-bootstrap/Dropdown";
import { useSelector } from "react-redux";

import { Box } from "@material-ui/core";

import { DropdownMenu4 } from "../../../../_partials/dropdowns";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";

export function UserProfileDropdown() {
  const { user } = useSelector((state) => state.auth);
  function authenticate() {
    window.location.href =
      process.env.REACT_APP_LOGIN_URL + "/#/login?redirect=resumebuilder";
    return;
  }

  return (
    <Box className="d-flex align-items-center">
      {!user && (
        <Box className={"w-auto d-flex align-items-center px-2"}>
          <Button
            className={"mr-3"}
            variant="outlined"
            color="primary"
            onClick={authenticate}
          >
            Login
          </Button>
          <Button variant="outlined" color="primary" onClick={authenticate}>
            Signup
          </Button>
        </Box>
      )}
      {user && (
        <Dropdown drop="down" alignRight>
          <Dropdown.Toggle
            as={DropdownTopbarItemToggler}
            id="dropdown-toggle-user-profile"
          >
            <div
              className={
                "btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
              }
            >
              <span className="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">
                Hi,
              </span>{" "}
              <span className="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">
                {`${user.firstName} ${user.lastName}`}
              </span>
              <span className="symbol symbol-35 symbol-primary">
                <span className="symbol-label font-size-h5 font-weight-bold">
                  {user.firstName[0]}
                </span>
              </span>
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-md">
            <DropdownMenu4 />
          </Dropdown.Menu>
        </Dropdown>
      )}
    </Box>
  );
}
