import React from "react";
import { Box } from "@material-ui/core";

import { ResumeBuilder } from "../modules/Resume/pages/ResumeBuilder";
import { AuthDialog } from "./authDialog";

export const DashboardPage = () => {
  return (
    <Box>
      <AuthDialog />
      <ResumeBuilder />
    </Box>
  );
};
