import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useQuery, gql, useLazyQuery, useMutation } from "@apollo/client";
import crypto from "crypto";
import MuiAlert from "@material-ui/lab/Alert";

import {
  Modal,
  makeStyles,
  Card,
  CardContent,
  CardActions,
  Button,
  CircularProgress,
  CardHeader,
  IconButton,
  Snackbar,
} from "@material-ui/core";
import { CloseRounded } from "@material-ui/icons";

import { resumeActions } from "../_redux/resumeActions";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const GET_RESUME_HTML = gql`
  query GetResume($getResumeResumeBody: String!, $getResumeTheme: String!) {
    getResume(resumeBody: $getResumeResumeBody, theme: $getResumeTheme) {
      html
    }
  }
`;

const GET_RESUME_PDF = gql`
  query Query($getResumePdfResumeBody: String!, $getResumePdfTheme: String!) {
    getResumePDF(
      resumeBody: $getResumePdfResumeBody
      theme: $getResumePdfTheme
    ) {
      pdf
    }
  }
`;

const SAVE_RESUME = gql`
  mutation SaveResumeMutation(
    $saveResumeResumeName: String!
    $saveResumeResumeData: String!
    $saveResumeResumeId: String
  ) {
    saveResume(
      resumeName: $saveResumeResumeName
      resumeData: $saveResumeResumeData
      resumeId: $saveResumeResumeId
    ) {
      code
      message
      success
      resumeId
    }
  }
`;

function downloadBase64File(base64Data) {
  const linkSource = `data:application/pdf;base64,${base64Data}`;
  const downloadLink = document.createElement("a");
  downloadLink.href = linkSource;
  downloadLink.download = "resume.pdf";
  downloadLink.click();
}

function getPreviewCardStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

export default () => {
  const {
    resumeId,
    resumeData,
    currentTab,
    selectedResume,
    sampleResumeData,
    savedResumeHash,
    user,
    preview,
    resumePreviewLoader,
  } = useSelector((state) => ({
    resumeId: state.resume.resumeId,
    selectedResume:
      state.resume.resumeList[state.resume.selectedResume].identifier,
    currentTab: state.resume.tabValue,
    resumeData: state.resume.resumeData,
    sampleResumeData: state.resume.sampleResumeData,
    savedResumeHash: state.resume.savedResumeHash,
    user: state.auth.user,
    preview: state.resume.preview,
    resumePreviewLoader: state.resume.resumePreviewLoader,
  }));

  const currentResumeDataHash = crypto
    .createHash("sha256")
    .update(JSON.stringify(resumeData))
    .digest("hex");

  const classes = makeStyles((theme) => ({
    previewCard: {
      position: "absolute",
      width: `${60}%`,
      [theme.breakpoints.down("576")]: { width: `${100}vw` },
      [theme.breakpoints.down("769")]: { width: `${80}vw` },
      height: `${95}vh`,
      minHeight: "370px",
    },
    cardContentStyle: {
      height: currentTab === "0" ? "100%" : "75%",
    },
  }))();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [snackBarText, setSnackBarText] = React.useState("");
  const { loading: getResumeLoading, data: getResumeData } = useQuery(
    GET_RESUME_HTML,
    {
      variables: {
        getResumeResumeBody:
          currentTab === "0"
            ? JSON.stringify(sampleResumeData)
            : JSON.stringify(resumeData),
        getResumeTheme: selectedResume,
      },
      onCompleted() {
        dispatch(resumeActions.setResumePreviewLoader(false));
      },
    }
  );

  const [saveResume, { loading: saveResumeLoading }] = useMutation(
    SAVE_RESUME,
    {
      onCompleted(data) {
        const { code, resumeId } = data.saveResume;
        if (code === "200") {
          const hash = crypto
            .createHash("sha256")
            .update(JSON.stringify(resumeData))
            .digest("hex");
          setSnackBarText("Resume saved to dashboard");
          setOpen(true);
          dispatch(resumeActions.setResumeId(resumeId));
          dispatch(resumeActions.setSavedResumeHash(hash));
        }
      },
    }
  );
  const [pdfResume, { loading: getPDFLoading }] = useLazyQuery(GET_RESUME_PDF, {
    variables: {
      getResumePdfResumeBody:
        currentTab === "0"
          ? JSON.stringify(sampleResumeData)
          : JSON.stringify(resumeData),
      getResumePdfTheme: selectedResume,
    },
    onCompleted(data) {
      console.log(data.getResumePDF.pdf.length);
      downloadBase64File(data.getResumePDF.pdf);
    },
  });

  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  return (
    <div>
      <div>
        <Snackbar
          open={open}
          autoHideDuration={5000}
          onClose={handleSnackBarClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <Alert
            style={{ fontSize: "15px" }}
            onClose={handleSnackBarClose}
            severity="success"
          >
            {snackBarText}
          </Alert>
        </Snackbar>
      </div>
      <Modal
        style={{ overflowY: "auto" }}
        open={!getResumeLoading && preview}
        onClose={() => dispatch(resumeActions.setPreview(false))}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Card style={getPreviewCardStyle()} className={classes.previewCard}>
          <CardHeader
            className="pb-0 text-center"
            title={selectedResume}
            action={
              <IconButton
                size="medium"
                onClick={() => dispatch(resumeActions.setPreview(false))}
              >
                <CloseRounded />
              </IconButton>
            }
          />
          <CardContent className={classes.cardContentStyle}>
            <iframe
              id="resumeIframe"
              title="resume-preview"
              frameBorder="0"
              width="100%"
              height="100%"
              srcDoc={getResumeData && getResumeData.getResume.html}
            ></iframe>
          </CardContent>
          {currentTab !== "0" ? (
            <CardActions
              style={{ paddingRight: "16px", height: "30px" }}
              align="center"
            >
              <Button
                variant="contained"
                color="primary"
                className="text-white"
                disabled={getPDFLoading}
                style={{ marginLeft: "auto", width: "118px" }}
                onClick={() => {
                  // const iframeNode = document.getElementById("resumeIframe");
                  // const doc = new jsPDF();
                  // window.print(iframeNode);
                  // print(iframeNode);
                  pdfResume();
                }}
              >
                {getPDFLoading ? (
                  <CircularProgress
                    style={{ color: "#3699FF" }}
                    size="1.5rem"
                  />
                ) : (
                  "Export As PDF"
                )}
              </Button>
              {/* <Button
                variant="contained"
                color="primary"
                className="text-white"
              >
                Export As Text
              </Button> */}
              <Button
                variant="contained"
                color="primary"
                className="text-white"
                style={{ width: "152px" }}
                disabled={!user || currentResumeDataHash === savedResumeHash}
                onClick={() => {
                  console.log(
                    "🚀 ~ file: resumePreview.js ~ line 280 ~ resumeId",
                    resumeId
                  );
                  saveResume({
                    variables: {
                      saveResumeResumeId: resumeId,
                      saveResumeResumeName: selectedResume,
                      saveResumeResumeData: JSON.stringify(resumeData),
                    },
                  });
                }}
              >
                {saveResumeLoading ? (
                  <CircularProgress
                    style={{ color: "#3699FF" }}
                    size="1.5rem"
                  />
                ) : (
                  "Save to Dashboard"
                )}
              </Button>
            </CardActions>
          ) : (
            ""
          )}
        </Card>
      </Modal>
    </div>
  );
};
