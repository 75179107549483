export default [
  {
    file: "jsonresume-theme-kendall.png",
    title: "Theme Kendall",
    identifier: "jsonresume-theme-kendall",
  },
  {
    file: "jsonresume-theme-kwan.png",
    title: "Theme Kwan",
    identifier: "jsonresume-theme-kwan",
  },
  {
    file: "jsonresume-theme-onepage.png",
    title: "Theme Onepage",
    identifier: "jsonresume-theme-onepage",
  },
  {
    file: "jsonresume-theme-paper.png",
    title: "Theme Paper",
    identifier: "jsonresume-theme-paper",
  },
  {
    file: "jsonresume-theme-short.png",
    title: "Theme Short",
    identifier: "jsonresume-theme-short",
  },
  {
    file: "jsonresume-theme-spartan.png",
    title: "Theme Spartan",
    identifier: "jsonresume-theme-spartan",
  },
  {
    file: "jsonresume-theme-stackoverflow-ibic.png",
    title: "Theme StackOverflow",
    identifier: "jsonresume-theme-stackoverflow-ibic",
  },
];
