import React from "react";
import { Box } from "@material-ui/core";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import moment from "moment";

import * as auth from "../_redux/authRedux";

function LoginRedirect({ location, login }) {
  const history = useHistory();
  const token = new URLSearchParams(location.search).get("token");
  const redirectPath = new URLSearchParams(location.search).get("redirectPath");
  if (token) {
    console.log(token);
    const authToken = token;
    login(
      authToken,
      moment()
        .add(2, "hour")
        .format()
    );
    history.replace(redirectPath);
  } else {
    history.push("/");
  }
  return <Box></Box>;
}

export default injectIntl(connect(null, auth.actions)(LoginRedirect));
