import { createSlice } from "@reduxjs/toolkit";
import schema from "./resumeSchema";
import resumeList from "./resumeTemplates";

const initialResumeState = {
  resumeId: "",
  savedResumeHash: "",
  tabValue: "0",
  selectedResume: 2,
  resumeData: schema,
  sampleResumeData: schema,
  resumeList,
  shouldValidateTabs: true,
  preview: false,
  showIdeasDialog: false,
  showIdeasFor: -1,
  copiedIdeas: "",
  isGetResumeLoading: false,
  resumePreviewLoader: false,
};

export const resumeSlice = createSlice({
  name: "resumeBuilder",
  initialState: initialResumeState,
  reducers: {
    tabValue: (state, action) => {
      state.tabValue = action.payload;
    },
    selectedResume: (state, action) => {
      state.selectedResume = action.payload;
    },
    nextTab: (state) => {
      state.tabValue = (+state.tabValue + 1).toString();
    },
    previousTab(state) {
      state.tabValue = (+state.tabValue - 1).toString();
    },
    selectTab: (state, action) => {
      state.tabValue = action.payload.value;
    },
    selectResume: (state, action) => {
      state.selectedResume = action.payload.value;
    },
    setPreview: (state, action) => {
      state.preview = action.payload;
    },
    setShowIdeasDialog: (state, action) => {
      state.showIdeasDialog = action.payload;
    },
    setShowIdeasFor: (state, action) => {
      state.showIdeasFor = action.payload;
    },
    setCopiedIdeas: (state, action) => {
      state.copiedIdeas = action.payload;
    },
    setResumeId: (state, action) => {
      state.resumeId = action.payload;
    },
    setSavedResumeHash: (state, action) => {
      state.savedResumeHash = action.payload;
    },
    updateResumeData: (state, action) => {
      state.resumeData = action.payload;
    },
    updatePersonalInfo: (state, action) => {
      const u = action.payload;
      state.resumeData.basics.name = `${u.firstName} ${u.lastName}`;
      state.resumeData.basics.phone = u.phone;
      state.resumeData.basics.email = u.email;
      state.resumeData.basics.location = {
        address: u.addressLine1,
        postalCode: u.postcode,
        city: u.city,
      };
    },
    updateWorkExperience: (state, action) => {
      const workList = action.payload.workExperienceList;
      state.resumeData.work = workList.map((work) => ({
        company: work.companyName,
        website: work.companyWebsite,
        position: work.jobPosition,
        startDate: work.startDate,
        endDate: work.isWorking ? "" : work.endDate,
        highlights: work.jobDescription.split("\n").filter((i) => i.length),
      }));
    },
    updateEducation: (state, action) => {
      const educations = action.payload.educations;
      state.resumeData.education = educations.map((e) => ({
        institution: e.institution,
        area: e.area,
        courses: e.courses.split(",").map((i) => i.trim()),
        studyType: e.studyType,
        startDate: e.startDate,
        endDate: e.isDoingStudy ? "" : e.endDate,
      }));
    },
    updateSkills: (state, action) => {
      const skills = action.payload.skills;
      state.resumeData.skills = skills.map((s) => ({
        name: s.name,
        level: s.level,
        keywords: s.keywords.split(",").map((k) => k.trim()),
      }));
    },
    setResumePreviewLoader: (state, action) => {
      state.resumePreviewLoader = action.payload;
    },
  },
});
