import React from "react";
import {
  Box,
  Typography,
  colors,
  List,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  makeStyles,
  Button,
  Icon,
  Grid,
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import * as Yup from "yup";
import { FieldArray, Formik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { ExpandMore } from "@material-ui/icons";
import moment from "moment";

import BottomNav from "../BottomNav";
import { resumeActions } from "../../_redux/resumeActions";

import WorkExperienceForm from "./workExperienceForm";
import IdeasModal from "./ideasModal";

export default () => {
  let arrayHelpers;

  const useStyles = makeStyles({
    heading: {
      color: colors.grey[700],
    },
    expansionDetails: {
      flexDirection: "column",
    },
  });
  const classes = useStyles();
  const dispatch = useDispatch();

  const { resumeData } = useSelector((state) => ({
    resumeData: state.resume.resumeData,
  }));
  // const [showIdeasIndex, setShowIdeasIndex] = React.useState(0);

  function getNewWorkExperience() {
    const workDetails = {
      companyName: "",
      companyWebsite: "",
      jobPosition: "",
      startDate: moment().format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      isWorking: false,
      jobDescription: "",
    };
    return workDetails;
  }

  const deleteWorkExperience = (index) => {
    arrayHelpers.remove(index);
  };

  const addNewExperience = () => {
    arrayHelpers.push(getNewWorkExperience());
  };

  const initialValues = {
    workExperienceList: resumeData.work.map((w) => ({
      companyName: w.company,
      companyWebsite: w.website,
      jobPosition: w.position,
      startDate: w.startDate || moment().format("YYYY-MM-DD"),
      endDate: w.endDate || moment().format("YYYY-MM-DD"),
      isWorking: false,
      jobDescription: w.highlights.join("\n\n"),
    })),
  };

  const validationSchema = Yup.object().shape({
    workExperienceList: Yup.array().of(
      Yup.object().shape({
        companyName: Yup.string().required("Company name is required"),
        // companyWebsite: Yup.string().required("Company website is required"),
        jobPosition: Yup.string().required("Job position is required"),
        startDate: Yup.string().required("Start data is required"),
        endDate: Yup.string().required("End date is required"),
        isWorking: Yup.boolean().required(),
        jobDescription: Yup.string(),
      })
    ),
  });

  function setArrayHelpers(value) {
    arrayHelpers = value;
  }

  // function getHelperTextForArray(
  //   formik,
  //   arrayKey,
  //   index,
  //   fieldKey,
  //   initialHelper
  // ) {
  //   const helperText =
  //     formik.touched &&
  //     formik.touched[arrayKey] &&
  //     formik.touched[arrayKey][index] &&
  //     formik.touched[arrayKey][index][fieldKey] &&
  //     formik.errors &&
  //     formik.errors[arrayKey] &&
  //     formik.errors[arrayKey][index] &&
  //     formik.errors[arrayKey][index][fieldKey]
  //       ? formik.errors[arrayKey][index][fieldKey]
  //       : initialHelper && "";
  //   return helperText;
  // }

  // const isInputErrorForArray = (formik, arrayKey, index, fieldKey) => {
  //   const isError =
  //     formik.touched &&
  //     formik.touched[arrayKey] &&
  //     formik.touched[arrayKey][index] &&
  //     formik.touched[arrayKey][index][fieldKey] &&
  //     formik.errors &&
  //     formik.errors[arrayKey] &&
  //     formik.errors[arrayKey][index] &&
  //     formik.errors[arrayKey][index][fieldKey]
  //       ? true
  //       : false;
  //   return isError;
  // };

  async function validateForm(formik) {
    const res = await formik.validateForm();
    if (Object.keys(res).length === 0) {
      dispatch(resumeActions.updateWorkExperience(formik.values));
      let data = {
        work: []
      }
      // console.log(formik.values, 'u');
      const workList = formik.values.workExperienceList;
      data.work = workList.map((u) => ({
        company: u.companyName,
        website: u.companyWebsite,
        position: u.jobPosition,
        startDate: u.startDate,
        endDate: u.isWorking ? "" : u.endDate,
        highlights: u.jobDescription.split("\n").filter((i) => i.length),
      }));
      return data;
    }
    formik.submitForm();
    return false;
  }

  return (
    <Box>
      <Typography variant="h5" classes={{ h5: classes.heading }}>
        Work History
      </Typography>
      <Formik initialValues={initialValues} validationSchema={validationSchema}>
        {(formik) => {
          return (
            <>
              <List>
                <FieldArray
                  name="workExperienceList"
                  render={(arrayHelpers) => {
                    setArrayHelpers(arrayHelpers);
                    return formik.values.workExperienceList.map((_, index) => (
                      <div key={index}>
                        <ExpansionPanel elevation={0} defaultExpanded={true}>
                          <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                            <Box display="flex" width={1} alignItems="center">
                              <Box flexGrow={1}>
                                <Typography
                                  variant="h6"
                                  classes={{ h6: classes.heading }}
                                >
                                  Company Work Experience {index + 1}
                                </Typography>
                              </Box>
                              <Box>
                                <Delete
                                  aria-label="Acknowledge"
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    deleteWorkExperience(index);
                                  }}
                                />
                              </Box>
                            </Box>
                          </ExpansionPanelSummary>
                          <ExpansionPanelDetails
                            className={classes.expansionDetails}
                          >
                            <WorkExperienceForm index={index} formik={formik} />
                            {/* <Box>
                              <Grid
                                container
                                spacing={4}
                                justify="space-between"
                              >
                                <Grid item xs={12} sm={4}>
                                  <TextField
                                    name={`workExperienceList[${index}].companyName`}
                                    label="Company Name"
                                    helperText={getHelperTextForArray(
                                      formik,
                                      "workExperienceList",
                                      index,
                                      "companyName"
                                    )}
                                    error={isInputErrorForArray(
                                      formik,
                                      "workExperienceList",
                                      index,
                                      "companyName"
                                    )}
                                    variant="outlined"
                                    {...formik.getFieldProps(
                                      `workExperienceList[${index}].companyName`
                                    )}
                                    fullWidth={true}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                  <TextField
                                    label="Company website"
                                    variant="outlined"
                                    fullWidth={true}
                                    name={`workExperienceList[${index}].companyWebsite`}
                                    helperText={getHelperTextForArray(
                                      formik,
                                      "workExperienceList",
                                      index,
                                      "companyWebsite"
                                    )}
                                    error={isInputErrorForArray(
                                      formik,
                                      "workExperienceList",
                                      index,
                                      "companyWebsite"
                                    )}
                                    {...formik.getFieldProps(
                                      `workExperienceList[${index}].companyWebsite`
                                    )}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                  <TextField
                                    label="Job Position"
                                    variant="outlined"
                                    fullWidth={true}
                                    name={`workExperienceList[${index}].jobPosition`}
                                    helperText={getHelperTextForArray(
                                      formik,
                                      "workExperienceList",
                                      index,
                                      "jobPosition"
                                    )}
                                    error={isInputErrorForArray(
                                      formik,
                                      "workExperienceList",
                                      index,
                                      "jobPosition"
                                    )}
                                    {...formik.getFieldProps(
                                      `workExperienceList[${index}].jobPosition`
                                    )}
                                  />
                                </Grid>
                              </Grid>
                            </Box>
                             */}
                            {/* <Box mt={5}>
                              <Grid
                                container
                                spacing={4}
                                justify="space-between"
                              >
                                <Grid item xs={12} md={4}>
                                  <Grid
                                    container
                                    spacing={2}
                                    justify="space-between"
                                  >
                                    <Grid item xs={12} sm={6}>
                                      <TextField
                                        label="Start Date"
                                        type="date"
                                        variant="outlined"
                                        fullWidth={true}
                                        helperText={getHelperTextForArray(
                                          formik,
                                          "workExperienceList",
                                          index,
                                          "startDate"
                                        )}
                                        error={isInputErrorForArray(
                                          formik,
                                          "workExperienceList",
                                          index,
                                          "startDate"
                                        )}
                                        {...formik.getFieldProps(
                                          `workExperienceList[${index}].startDate`
                                        )}
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                      <TextField
                                        label="End Date"
                                        type="date"
                                        variant="outlined"
                                        fullWidth={true}
                                        helperText={getHelperTextForArray(
                                          formik,
                                          "workExperienceList",
                                          index,
                                          "endDate"
                                        )}
                                        error={isInputErrorForArray(
                                          formik,
                                          "workExperienceList",
                                          index,
                                          "endDate"
                                        )}
                                        {...formik.getFieldProps(
                                          `workExperienceList[${index}].endDate`
                                        )}
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item xs={12} md={8}>
                                  <Grid container>
                                    <Grid item xs={12}>
                                      <TextField
                                        label="Job Description"
                                        multiline
                                        rows={10}
                                        variant="outlined"
                                        fullWidth={true}
                                        helperText={getHelperTextForArray(
                                          formik,
                                          "workExperienceList",
                                          index,
                                          "jobDescription"
                                        )}
                                        error={isInputErrorForArray(
                                          formik,
                                          "workExperienceList",
                                          index,
                                          "jobDescription"
                                        )}
                                        {...formik.getFieldProps(
                                          `workExperienceList[${index}].jobDescription`
                                        )}
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Box>
                             */}
                          </ExpansionPanelDetails>
                        </ExpansionPanel>
                      </div>
                    ));
                  }}
                ></FieldArray>
              </List>
              <Grid container justify="center" alignItems="center" spacing={3}>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    className="text-white"
                    onClick={addNewExperience}
                  >
                    <Icon className="fa fa-plus-circle" />
                    <Box ml={1}>Add Experience</Box>
                  </Button>
                </Grid>
              </Grid>
              <Box mt={5}>
                <BottomNav validate={() => validateForm(formik)} />
              </Box>
            </>
          );
        }}
      </Formik>
      <IdeasModal />
    </Box>
  );
};
